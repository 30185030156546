import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { getMetaValue } from "../../../utils"

// Connects to data-controller="ai--request-form--form"
export default class extends Controller {
  static targets = ["docUploadWrapper", "form", "submitButton"]
  docUploadWrapperTarget: HTMLDivElement
  formTarget: HTMLFormElement
  submitButtonTarget: HTMLButtonElement

  static values = { url: String }
  urlValue: String

  private observer!: MutationObserver

  connect() {
    this.updateSubmitState()

    // Observer DOM changes within the docUploadWrapperTarget to update the submit button state
    this.observer = new MutationObserver(this.updateSubmitState.bind(this))
    this.observer.observe(this.docUploadWrapperTarget, { childList: true, subtree: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  updateSubmitState() {
    this.submitButtonTarget.disabled = this.uploadedFileInputs().length === 0
  }

  submitFileUpload = (event: Event) => {
    event.preventDefault()

    const signedIds = Array.from(this.uploadedFileInputs()).map((input: HTMLInputElement) => input.value)
    const message = [
      `Uploaded ${signedIds.length} file${signedIds.length === 1 ? "" : "s"}.`,
      `<MESSAGE_DATA>{"signed_document_ids": ${JSON.stringify(signedIds)}}</MESSAGE_DATA>`,
    ].join("\n")

    const formData = new FormData()
    formData.append("message", message)

    post(this.urlValue, {
      body: formData,
      headers: { "X-CSRF-Token": getMetaValue("csrf-token") },
    })
  }

  uploadedFileInputs(): HTMLInputElement[] {
    return Array.from(this.docUploadWrapperTarget.querySelectorAll('input[name="file_upload[file][]"][type="hidden"]'))
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["feedbackField"]

  toggleFeedback() {
    if (this.hasFeedbackFieldTarget) {
      this.feedbackFieldTarget.classList.remove("hidden")
      // Give browser time to process the display change before animating
      requestAnimationFrame(() => {
        this.feedbackFieldTarget.classList.remove("opacity-0", "-translate-y-4")
      })
    }
  }
}

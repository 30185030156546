import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../../utils"

// Connects to data-controller="staging-contracts--review-extractions-form"
export default class extends Controller {
  static targets = ["contentWrapper", "documentViewerWrapper"]

  contentWrapperTarget: HTMLDivElement
  documentViewerWrapperTarget: HTMLDivElement

  hasContentWrapperTarget: boolean
  hasDocumentViewerWrapperTarget: boolean

  connect() {
    this.saveOriginalCssClasses()
  }

  showDocumentViewer() {
    if (!this.hasDocumentViewerWrapperTarget) {
      return
    }

    show(this.documentViewerWrapperTarget)
    this.swapContentClasses("max-w-[720px]", "max-w-[400px]")
  }

  hideDocumentViewer() {
    if (!this.hasDocumentViewerWrapperTarget) {
      return
    }

    hide(this.documentViewerWrapperTarget)
    this.swapContentClasses("max-w-[400px]", "max-w-[720px]")
    this.removeInputHighlightClasses()
    this.updateQueryStringParams()
  }

  private saveOriginalCssClasses() {
    const inputs = this.contentWrapperTarget.querySelectorAll(".input-highlight")
    inputs.forEach((input) => {
      input.dataset.originalClass = input.className
    })
  }

  private swapContentClasses(oldClass: string, newClass: string) {
    if (!this.hasContentWrapperTarget) {
      return
    }

    this.contentWrapperTarget.classList.remove(oldClass)
    this.contentWrapperTarget.classList.add(newClass)
  }

  private removeInputHighlightClasses() {
    if (!this.hasContentWrapperTarget) {
      return
    }

    const inputs = this.contentWrapperTarget.querySelectorAll(".input-highlight")
    inputs.forEach((input) => {
      input.className = input.dataset.originalClass
    })
  }

  private updateQueryStringParams() {
    const url = new URL(window.location)
    url.searchParams.delete("document_id")
    window.history.pushState({}, "", url)
  }
}

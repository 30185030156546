import { Controller } from "@hotwired/stimulus"
import { isEmpty } from "lodash"

// Connects to data-controller="document-switcher"
export default class extends Controller {
  static targets = ["turboFrame"]

  turboFrameTarget: HTMLDivElement

  loadDocument(event) {
    if (event.currentTarget.dataset.documentUrl) {
      const url = event.currentTarget.dataset.documentUrl
      Turbo.visit(url, { frame: this.turboFrameTarget.id })
    }
  }
}
